import { useContext, useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa"
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import {productsColumns} from '../../components/tables/schemaColumn'
import { productsDownloads } from '../../components/tables/schemaDownload'
import { useHistory } from "react-router-dom"
import { Button } from 'antd'
import { ProfileOutlined } from '@ant-design/icons'
import ModalContainer from '../quoter/containers/ModalContainer'
import ProductSelector from '../../components/generals/ProductSelector'
import { getCustomFields } from "../../api/ProductAPI"
import { DataContext } from "../../context/dataContext"

const filterByColumnsFactory = ({
	country,
  isAdmin = false,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}) => ({
	country,
  isAdmin,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'SKUCliente', find: false },
    { id: 'Nombre', find: false },
    { id: 'Descripcion', find: false },
    { id: 'ValorMercancia', find: false },
    { id: 'PesoKG', find: false },
    { id: 'Largo', find: false },
    { id: 'Ancho', find: false },
    { id: 'Altura', find: false },
    { id: 'ValorMercancia', find: false },
    {
      id: 'Visible',
      find: true,
      filters: {
        sort: false,
        filter: true,
        filter_values: [true] 
      }
    },
  ]
})

const ActiveProducts = () => {
  const History = useHistory()
  const { getTokenSilently, user } = useAuth0()
  const { setInventoryId, setProductType } = useContext(DataContext)
  const [apiCustomFields, setApiCustomFields] = useState([])
  const [massiveDownload, setMassiveDownload] = useState(false)

  const [country] = useState('MX')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [dataColumns, setDataColumns] = useState(null) 
  const [filterByColumns, setFilterByColumns] = useState(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
  const [reload, setReload] = useState(false)
  const [initRender, setInitRender] = useState(false)

  const handleFecth = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const { data } = await axios.post(
        `/allproducts`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )

    setTotal(data?.total)

    if (saveState){
      setDataColumns(data?.data)
    } else {
      return data?.data ?? []
    }
  }

  const getAllCustomFields = async () => {
    const token = await getTokenSilently();
    const response = await getCustomFields(token, user.sub, "default")
    if (response.status === 200) {
      setApiCustomFields(response.data.CustomFields)
    }
  }

  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

  const handleReload = () => {
    setReload(!reload)
  }

  const handleMassiveDownloadModal = ()=> {
    setMassiveDownload(!massiveDownload)
  }

  const addProduct = () => {
    setProductType("default")
    setInventoryId("default")
    History.push("/products/create")
  }

  useEffect(() => {
    setInventoryId("default")
  }, [])//eslint-disable-line

	useEffect(() => {
		if (!initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
        await getAllCustomFields()
				setIsRefetching(false)
				setInitRender(true)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
        await getAllCustomFields()
				setIsRefetching(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [filterByColumns]) 
	
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <>
      <Table
        // title={"Productos"}
        tableType="products"
        data={dataColumns ?? []}
        total={total}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={productsDownloads}
        columns={productsColumns("active", handleReload, apiCustomFields) ?? []}
        config={{ disableFilters: true }}
        reload={handleReload}
        optionalContent={{
          options: (
            <>
              <div className="downloadXLSX">
                  <Button
                    onClick={() => setMassiveDownload(!massiveDownload)}
                    style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                    icon={<ProfileOutlined />}
                  >
                    Descargar codigos QR masivos
                  </Button>
              </div>
              <Button
                type="primary"
                onClick={() => addProduct()}
              >
                Agregar Producto
              </Button>
            </>
          )
        }}
        stateValues={{
          country,
          currentPage,
          rowsPerPages,
          searchText,
          filterByColumns,
        }}
        filters={{
          filterBySearchText(text) {
            setSearchText(text)
          },
          filterByColumns(filter) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter) => {
            filter.isDownload = true
            return await handleFecth(filter, false)
          },
          handleStateFilterByColumns: (props) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props)=> {
						return filterByColumnsFactory(props)
					}
        }}
      />
      <ModalContainer
        height = '100%'
        visible={massiveDownload}
        title="Descarga masiva de codigos QR"
        onCancel={()=> setMassiveDownload(false)}
        onOk={()=> {}}
        footer={false}
      >
        <ProductSelector 
          handleModal={handleMassiveDownloadModal}
        />
      </ModalContainer>
    </>
  )
}

export default ActiveProducts