import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import {Tag} from "antd";
import {DollarCircleOutlined} from "@ant-design/icons";

let styles = {
    display: 'flex',
    fontWeight: '500',
    width: '125px',
    height: '25px',
    borderRadius: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: '10px 0',
    imgStye: { 
        margin: '5px', 
        width: '17px', 
        color: 'white' 
    }
}

const icons = {
    Pendiente: <ReportProblemOutlinedIcon style={styles.imgStye} />,
    Procesado: <CheckCircleOutlinedIcon style={styles.imgStye} />,
    Enviado: <SendOutlinedIcon style={styles.imgStye} />,
    Recibido: <InboxOutlinedIcon style={styles.imgStye} />,
    Programado: <EventOutlinedIcon style={styles.imgStye} />,
    Cancelado: <ClearIcon style={styles.imgStye} />,
    Modificacion: <Edit style={styles.imgStye} />,
    Venta: <DollarCircleOutlined style={styles.imgStye} />
}

export const WarehouseOutputStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 0:
            styles.backgroundColor = '#54C0F9'
            return (
                <div style={{ ...styles }}>
                    {icons.Pendiente} Pendiente
                </div>
            )
        case 1:
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Procesado} Procesado
                </div>
            )
    }
}

export const WarehouseShipmentStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 0:
            styles.backgroundColor = '#54C0F9'
            return (
                <div style={{ ...styles }}>
                    {icons.Enviado} Enviado
                </div>
            )
        case 1:
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Recibido} Recibido
                </div>
            )
        case 2:
            styles.backgroundColor = '#FF4D4F'
            return (
                <div style={{ ...styles }}>
                    {icons.Cancelado} Cancelado
                </div>
            )
    }
}
    
export const OutboundShipmentStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 0:
            styles.backgroundColor = '#54C0F9'
            return (
                <div style={{ ...styles }}>
                    {icons.Programado} Programado
                </div>
            )
        case 1:
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Enviado} Enviado
                </div>
            )
    }
}

export const MovementsStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 'MODIFICACION':
            styles.backgroundColor = '#ffba00'
            return (
                <div style={{ ...styles }}>
                    {icons.Modificacion} Modificación
                </div>
            )
        case "CANCELACION":
            styles.backgroundColor = '#FF4D4F'
            return (
                <div style={{ ...styles }}>
                    {icons.Cancelado} Cancelado
                </div>
            )
        case "RECEPCION":
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Recibido} Recepción
                </div>
            )
        case 'ENVIO':
            styles.backgroundColor = '#54c0f9'
            return (
                <div style={{ ...styles }}>
                    {icons.Enviado} Envío
                </div>
            )
        case 'VENTA':
            styles.backgroundColor = '#5bad63'
            return (
                <div style={{...styles}}>
                    {icons.Venta} Venta
                </div>
            )
    }
}

export const IntegrationShipmentStatus = (state, type) => {
    switch (type) {
        case 'BADGE':
            switch (state) {
                case 'ready_to_ship':
                case 'processing':
                    return (
                        <Tag color="geekblue" style={{ width:'100%', textAlign:'center' }}>
                            Procesando
                        </Tag>
                    )
                case 'IN_PROGRESS':
                    return (
                        <Tag color="geekblue" style={{ width:'100%', textAlign:'center' }}>
                            En progreso
                        </Tag>
                    )
                case 'delivered':
                    return (
                        <Tag color="blue" style={{ width:'100%', textAlign:'center' }}>
                            Enviado
                        </Tag>
                    )
                case 'shipped':
                    return (
                        <Tag color="green" style={{ width:'100%', textAlign:'center' }}>
                            Entregado
                        </Tag>
                    )
                case 'completed':
                case 'FULFILLED':
                    return (
                        <Tag color="green" style={{ width:'100%', textAlign:'center' }}>
                            Completado
                        </Tag>
                    )
                case 'cancelled':
                    return (
                        <Tag color="red" style={{ width:'100%', textAlign:'center' }}>
                            Cancelado
                        </Tag>
                    )
                case 'pending':
                    return (
                        <Tag color="volcano" style={{ width:'100%', textAlign:'center' }}>
                            Pendiente
                        </Tag>
                    )
                case 'OPEN':
                    return (
                        <Tag color="volcano" style={{ width:'100%', textAlign:'center' }}>
                            Abierto
                        </Tag>
                    )
                case 'on-hold':
                case 'ON_HOLD':
                    return (
                        <Tag color="geekblue" style={{ width:'100%', textAlign:'center' }}>
                            En espera
                        </Tag>
                    )
                case 'failed':
                    return (
                        <Tag color="red" style={{ width:'100%', textAlign:'center' }}>
                            Fallido
                        </Tag>
                    )
                case 'UNFULFILLED':
                    return (
                        <Tag color="blue" style={{ width:'100%', textAlign:'center' }}>
                            No cumplido
                        </Tag>
                    )
                case 'refunded':
                    return (
                        <Tag color="blue" style={{ width:'100%', textAlign:'center' }}>
                            Reembolsado
                        </Tag>
                    )
                default:
                    return (
                        <Tag color="purple">
                            { state }
                        </Tag>
                    )
            }
        case 'NORMALIZE':
            switch (state) {
                case 'ready_to_ship':
                case 'processing':
                    return "Procesando"
                case 'IN_PROGRESS':
                    return "En progreso"
                case 'delivered':
                    return "Enviado"
                case 'shipped':
                    return "Entregado"
                case 'completed':
                case 'FULFILLED':
                    return "Completado"
                case 'cancelled':
                    return "Cancelado"
                case 'pending':
                    return "Pendiente"
                case 'OPEN':
                    return "Abierto"
                case 'on-hold':
                case 'ON_HOLD':
                    return "En espera"
                case 'failed':
                    return "Fallido"
                case 'UNFULFILLED':
                    return "No cumplido"
                case 'refunded':
                    return "Reembolsado"
                default:
                    return state
            }
        default:
            return state
    }
}

export const MercadoLibreStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 'paused':
            styles.backgroundColor = '#ffba00'
            return (
                <div style={{ ...styles }}>
                    Pausado
                </div>
            )
        case "cancelled":
            styles.backgroundColor = '#FF4D4F'
            return (
                <div style={{ ...styles }}>
                    Cancelado
                </div>
            )
        case "active":
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    Activo
                </div>
            )
        default:
            styles.backgroundColor = '#54C0F9'
            return (
                <div style={{...styles}}>
                    state
                </div>
            )
    }
}
