import React, {Fragment, useState, useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from "react-images-uploading";

import {CreateMassiveProducts, CreateProduct, getCustomFields} from "../../api/ProductAPI";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import { useAlert } from "react-alert";
import { DataContext } from "../../context/dataContext";
import {
  Card,
  Divider,
  Button,
  Row,
  Col,
  Tooltip,
  Select,
  Form,
  InputNumber,
  Table,
  Popconfirm
} from "antd";
import {CreateCustomProductWarehouses} from "../../api/userBalanceData";
import {DeleteOutlined, PlusCircleOutlined, ProfileOutlined} from "@ant-design/icons";
import ModalContainer from "../quoter/containers/ModalContainer";
import { axiosBase as axios } from "../../api/AxiosConfig";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
}));

const numberValues = ["PesoKG","Largo", "Ancho", "Altura", "ValorMercancia", "VentaAmazon", "VentaEcommerce", "VentaMeli"];

const ProductCreate = (props) => {

  const [apiCustomFields, setApiCustomFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [productsOptions, setProductsOptions] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [drevfillProducts, setDrevfillProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  const classes = useStyles();
  const [images, setImages] = useState([]);
  const { getTokenSilently, user } = useAuth0();
  const History = useHistory();
  const alert = useAlert();
  const { data, setData, inventoryId, productType } = useContext(DataContext);

  const initialState = {
    SKUCliente: "",
    Nombre: "",
    PesoKG: "",
    Largo: "",
    Ancho: "",
    Altura: "",
    Descripcion: "",
    Fotografia: "",
    Fotografia64: "",
    ValorMercancia: "",
    VentaAmazon: "",
    VentaEcommerce: "",
    VentaMeli: "",
    CustomFields: {},
    CantidadEnInventario: ""
  };
  const [product, setProduct] = useState(initialState);
  const [form] = Form.useForm()

  const onChangeImage = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProduct({
      ...product,
      Fotografia64: imageList.length > 0 ? imageList[0].data_url : "",
    });
  };

  const onFinish = async (e) => {
    // e.preventDefault();
    setIsLoading(true)
    if (
      product.SKUCliente !== '' &&
      product.Nombre !== '' &&
      product.PesoKG !== '' &&
      product.Largo !== '' &&
      product.Ancho !== '' &&
      product.Altura !== '' &&
      product.Descripcion !== '' &&
      product.Fotografia64 &&
      product.ValorMercancia !== 0 &&
      product.ValorMercancia !== null &&
      product.ValorMercancia !== ''
    ) {
      numberValues.forEach(item => product[item] = Number(product[item]))
      const t = await getTokenSilently();

      if(inventoryId === 'default'){
        await CreateProduct(t, product);
      }

      if(inventoryId !== 'default'){
        const request = {
          ...product,
          InventoryId: inventoryId
        }
        await CreateCustomProductWarehouses(t, request)
      }

      setIsLoading(false)
      alert.success("Tu producto fue registrado con éxito");
      setData({ ...data, updateData: true });
      History.push("/products");
    } else {
      setIsLoading(false)
      alert.info(
        "Debes seleccionar todos los campos"
      );
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    if (numberValues.includes(name)){
      if (/^\d*\.?\d*$/.test(value)) {
        setProduct({ ...product, [name]: value });
      }
    } else {
      if(name === 'Descripcion'){
        if(value.length < 80){
          setProduct({ ...product, [name]: value });
        } else return
      }
      setProduct({ ...product, [name]: value })
    }
  };

  const onChangeCustomInput = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, CustomFields: { ...product.CustomFields, [name]: value } });
  }

  const getAllCustomFields = async () => {
    const token = await getTokenSilently();
    const response = await getCustomFields(token, user.sub, inventoryId);
    if (response.status === 200) {
      setApiCustomFields(response.data.CustomFields);
    }
  };


  useEffect(() => {
    getAllCustomFields()
    //eslint-disable-next-line
  }, []);


  // Columns and data for duplicate Drevfill products to CustomWarehouse

  const getAllDrevfillProducts = async() => {
    setLoadingProducts(true)
    const userToken = await getTokenSilently();
    const { data } = await axios.get(
        `/find/allproducts`,
        { headers: { Authorization: `Bearer ${userToken}` }}
    )
    if(data) {
      setDrevfillProducts(data)
      const options = data.map(item => {
        return { label: `${item.SKUCliente} - ${item.Nombre}`, value: item._id };
      })
      setProductsOptions(options)
    }
    setLoadingProducts(false)
  }

  useEffect(() => {
    if(productType === 'customWarehouse'){
      getAllDrevfillProducts();
    }
  }, [productType]);//eslint-disable-line

  // ----------------- INVENTARIO PERSONALIZADO - Función únicamente para añadir productos a duplicar - INVENTARIO PERSONALIZADO ----------------------- //
  const onAddProduct = (e) => {
    const validateExist = selectedProducts.find(el => el.SKUFulfillment === e.productId)
    if(validateExist){
      setSelectedProducts((prevState) =>
        prevState.map((item) =>
          item.SKUFulfillment === e.productId ? { ...item, CantidadEnInventario: item.CantidadEnInventario + e.quantity } : item
        )
      )
    } else {
      let product = drevfillProducts.find(el => el._id === e.productId)
      delete product?.UpdatedAt
      delete product?.CreatedAt
      delete product?.Tracking
      delete product?.SKUFulfillment
      delete product?._id
      product.InventoryId = inventoryId
      product.CantidadEnInventario = e.quantity
      product.Visible = true
      setSelectedProducts((prevState) => [...prevState, product])
    }
    form.resetFields()
  }

  const deleteProduct = (skuCliente, productName) => {
    const response = selectedProducts.filter(el => el.SKUCliente !== skuCliente && el.Nombre !== productName)
    setSelectedProducts(response)
  }

  const columsProducts = [
    {
      title: "Fotografia",
      dataIndex: "",
      align: 'center',
      render: (product)=> (
          <img
              src={product.Fotografia}
              alt={product.Nombre}
              width="80"
              height="80"
          />
      )
    },
    {
      title: "SKU",
      align: 'center',
      dataIndex: "SKUCliente"
    },
    {
      title: "Nombre",
      align: 'center',
      dataIndex: "Nombre",
    },
    // {
    //   title: "Descripcion",
    //   align: 'center',
    //   dataIndex: "Descripcion"
    // },
    {
      title: "Cantidad",
      dataIndex: "CantidadEnInventario"
    },
    {
      title: "Costo",
      align: 'center',
      dataIndex: "ValorMercancia",
    },
    {
      title: "Dimensiones ",
      dataIndex: "",
      align: 'center',
      render: (obj) => `${obj.Largo} x ${obj.Ancho} x ${obj.Altura}`
    },
    {
      title: "Peso",
      dataIndex: "PesoKG",
    },
    {
      title: "Acciones",
      dataIndex: "",
      align: 'center',
      render: (el) => {
        return (
            <Tooltip title="Eliminar para NO agregar este producto a tu bodega.">
              <Popconfirm
                  title="¿Deseas eliminar este producto?"
                  description="No se agregara a tu bodega personalizada."
                  onConfirm={() => deleteProduct(el.SKUCliente, el.Nombre)}
              >
                <Button type="primary" icon={<DeleteOutlined style={{ fontSize: '20px' }} />} danger />
              </Popconfirm>
            </Tooltip>
        )
      }
    }
  ]

  const onCancelAdd = () => {
    setSelectedProducts([])
    setOpenModal(false)
  }

  const chargeProducts = async() => {
    setLoadingProducts(true)
    const token = await getTokenSilently()
    try {
      if(selectedProducts.length > 0){
        const response = await CreateMassiveProducts(token, selectedProducts, 'customWarehouse')
        if(response.status === 200){
          setLoadingProducts(false)
          form.resetFields()
          setSelectedProducts([])
          setOpenModal(false)
          History.push("/products");
          return alert.success("Productos añadidos correctamente!")
        }
        if(response.response.data.message === "Error de producto duplicado"){
          setLoadingProducts(false)
          alert.error("No se pueden subir nuevamente productos que ya haz cargado.")
          alert.info("Revisa tus productos para que no subas duplicados")
          return
        }
      }
      setLoadingProducts(false)
    } catch (error) {
      setLoadingProducts(false)
      return alert.error('Error al agregar productos, e intenta nuevamente.')
    }
  }

  return (
    <Fragment>
      <Box>
        <Card>
          <CardContent>
            <Row gutter={[10,10]}>
              <Col>
                <Button icon={<ProfileOutlined />} style={{ textTransform:'uppercase' }} type="primary" htmlType="submit" onClick={() => History.push('/products/create/masive')}>Agregar masivamente desde excel</Button>
              </Col>
              {
               productType === 'customWarehouse' && (
                  <Col>
                    <Button icon={<PlusCircleOutlined />} onClick={() => setOpenModal(true)}>Duplicar productos de Drevfill</Button>
                  </Col>
               )
              }
            </Row>
            <Form 
              // form={form}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Form.Item
                    name="SKUCliente"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >
                    <TextField
                      name="SKUCliente"
                      label="SKU"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      required={true}
                      value={product.SKUCliente}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item
                    name="Nombre"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >
                    <TextField
                      name="Nombre"
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      required={true}
                      value={product.Nombre}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item
                    name="Descripcion"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >
                    <TextField
                      name="Descripcion"
                      label="Descripción corta"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      value={product.Descripcion}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item
                    name="Largo"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >
                    <TextField
                      name="Largo"
                      label="Largo"
                      variant="outlined"
                      type="number"
                      fullWidth
                      className={classes.textField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">CM</InputAdornment>
                        ),
                      }}
                      required={true}
                      value={product.Largo}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item
                    name="Ancho"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >
                    <TextField
                      name="Ancho"
                      label="Ancho"
                      variant="outlined"
                      type="number"
                      fullWidth
                      className={classes.textField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">CM</InputAdornment>
                        ),
                      }}
                      required={true}
                      value={product.Ancho}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item
                    name="Altura"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  > 
                    <TextField
                      name="Altura"
                      label="Altura"
                      variant="outlined"
                      type="number"
                      fullWidth
                      className={classes.textField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">CM</InputAdornment>
                        ),
                      }}
                      required={true}
                      value={product.Altura}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item
                    name="PesoKG"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >    
                    <TextField
                      name="PesoKG"
                      label="Peso"
                      variant="outlined"
                      type="number"
                      fullWidth
                      className={classes.textField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">KG</InputAdornment>
                        ),
                      }}
                      required={true}
                      value={product.PesoKG}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                </Grid>
                {
                    (productType !== 'customWarehouse')
                    && (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              name="VentaAmazon"
                              label="Venta Amazon (opcional)"
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              value={product.VentaAmazon}
                              onChange={onChangeInput}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              name="VentaMeli"
                              label="Venta MercadoLibre (opcional)"
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              value={product.VentaMeli}
                              onChange={onChangeInput}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              name="VentaEcommerce"
                              label="Venta Ecommerce (opcional)"
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              value={product.VentaEcommerce}
                              onChange={onChangeInput}
                            />
                          </Grid>
                        </>
                    )
                }
                {
                    productType === 'customWarehouse' && (
                        <Grid item xs={12}>
                          <Form.Item
                            name="CantidadEnInventario"
                            rules={[{ required: true, message: "Campo requerido" }]}
                          >  
                            <TextField
                                type="number"
                                name="CantidadEnInventario"
                                label="Cantidad en inventario"
                                variant="outlined"
                                fullWidth
                                className={classes.textField}
                                required={true}
                                value={product.CantidadEnInventario}
                                onChange={onChangeInput}
                            />
                          </Form.Item>
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                  <Form.Item
                    name="ValorMercancia"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >
                    <TextField
                      type="number"
                      name="ValorMercancia"
                      label="Costo"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      required={true}
                      value={product.ValorMercancia}
                      onChange={onChangeInput}
                    />
                  </Form.Item>  
                  {
                    (productType !== 'customWarehouse') && (
                      <label>
                        El monto máximo a cubrir por el seguro es de $ 2,000
                      </label>
                      )
                  }
                </Grid>

                <Divider style={{ marginTop:'40px' }}>Campos personalizados ( opcionales )</Divider>
                {
                  apiCustomFields.length > 0 && (
                    apiCustomFields.map(item => (
                          <Grid item xs={12}>
                            <TextField
                                type={item.type === "number" ? "number" : "text"}
                                name={item?.fieldName}
                                label={item?.fieldName}
                                variant="outlined"
                                fullWidth
                                className={classes.textField}
                                onChange={onChangeCustomInput}
                            />
                          </Grid>
                      )) || (apiCustomFields.length === 0 && (<p>Puedes crear campos personalizados en la sección de configuración</p>))
                    )
                }
                <Grid item xs={12}>
                  <InputLabel className={classes.textField}>
                    Fotografía del producto
                  </InputLabel>

                  <Form.Item
                    name="Foto"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                  >
                    <ImageUploading
                      value={images}
                      onChange={onChangeImage}
                      maxNumber={1}
                      dataURLKey="data_url"
                      acceptType={['jpg', 'png']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper">
                          <Box mt={1}>
                            {imageList.length > 0 ? (
                              <Button
                                type="primary"
                                htmlType="submit"
                                style={{ textTransform:'uppercase' }}
                                onClick={onImageRemoveAll}
                              >
                                Quitar imagen
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                htmlType="submit"
                                onClick={onImageUpload}
                                style={{ textTransform:'uppercase' }}
                                {...dragProps}
                              >
                                Selecciona una imagen
                              </Button>
                            )}
                          </Box>
                          <Box mt={2}>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image.data_url}
                                  alt=""
                                  width="200"
                                  height="auto"
                                />
                              </div>
                            ))}
                          </Box>
                        </div>
                      )}
                    </ImageUploading>
                  </Form.Item>
                </Grid>
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    danger
                    style={{ textTransform:'uppercase' }}
                    onClick={() => History.push("/products")}
                  >
                    Cancelar
                  </Button>
                  &nbsp;
                  <Button type="primary" htmlType="submit" disabled={isLoading} style={{ textTransform:'uppercase' }}>
                    { isLoading ? "Cargando..." : "Guardar" }
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </Box>

      {/* ---------------------------- Sección de duplicar productos ------------------------------- */}
      <ModalContainer
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          footer={<></>}
          centered={true}
          title="Duplicar productos"
          size="60em"
      >
        <p>Selecciona los productos que tienes en Drevfill y duplicalos en tu bodega personalizada.</p>
        <Form onFinish={onAddProduct} form={form}>
          <Row gutter={[5, 10]}>
            <Col span={14}>
              <Form.Item name="productId" rules={[{required: true, message: "Este campo requerido"}]}>
                <Select
                    showSearch
                    allowClear
                    placeholder="Selecciona el producto"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    loading={loadingProducts}
                    options={productsOptions}
                    style={{width: '100%'}}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="quantity" rules={[{required: true, message: "Este campo requerido"}]}>
                <InputNumber placeholder="Cantidad" style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button style={{width: '100%'}} type="primary" htmlType="submit">Añadir</Button>
            </Col>
          </Row>
        </Form>
        <Table dataSource={selectedProducts ?? []} columns={columsProducts} loading={loadingProducts} pagination={{ pageSize: 4 }} />
        <Row gutter={[5, 10]}>
          <Col span={6}>
            <Button style={{ width: '100%' }} type="primary" danger onClick={onCancelAdd} loading={loadingProducts}>Cancelar</Button>
          </Col>
          <Col span={6}>
            <Popconfirm title="¿Quieres agregar estos productos a tu inventario personalizado?" onConfirm={chargeProducts}>
              <Button disabled={selectedProducts.length === 0} style={{ width: '100%' }} type="primary" loading={loadingProducts}>Cargar Productos</Button>
            </Popconfirm>
          </Col>
        </Row>
      </ModalContainer>

    </Fragment>
  );
};

export default ProductCreate;
