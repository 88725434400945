import {useContext, useEffect, useState} from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import {usersByIntegration} from '../../components/tables/schemaColumn';
import {Button, Card} from "antd";
import {usersByIntegrationDownloads} from "../../components/tables/schemaDownload";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {QuoteDataContext} from "../../context/QuoteDataContext";

const filterByColumnsFactory = ({
	country,
    isAdmin = true,
    type,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}) => ({
	country,
    isAdmin,
    type,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
        { id: 'name', find: false },
        { id: 'created_at', find: false },
        { id: 'url', find: false }
    ]
})

const IntegrationCustomers = ({ integration, setShowIntegration, setOpenModal }) => {
    const { getTokenSilently } = useAuth0();
    const [country] = useState('MX')
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPages, setRowPerPages] = useState(20)
    const [total, setTotal] = useState(0)
    const [refetching, setIsRefetching] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [dataColumns, setDataColumns] = useState(null) 
    const [filterByColumns, setFilterByColumns] = useState(filterByColumnsFactory({ country, type: integration, currentPage, rowsPerPages, searchText }))
    const [reload, setReload] = useState(false)
    const [initRender, setInitRender] = useState(false)
    const { setCustomerIntegrationData } = useContext(QuoteDataContext)

    const handleFecth = async (filterByColumns, saveState = true) => {
        const userToken = await getTokenSilently()
        const { data } = await axios.post(
            `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/users/fulfillment/getUsersByIntegration`,
            filterByColumns,
            { headers: { Authorization: `Bearer ${userToken}` }}
        )
    
        setTotal(data?.total)
    
        if (saveState){
          setDataColumns(data?.data)
        } else {
          return data?.data ?? []
        }
    }
    
    const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }) => {	
        const customFilterByColumns = { 
            ...filterByColumns,
        }
    
        if (user) customFilterByColumns.user = user	
        if (country) customFilterByColumns.country = country
        if (searchText !== undefined) customFilterByColumns.searched = searchText
        
        if (currentPage){
            setCurrentPage(currentPage)
            customFilterByColumns.range.from = currentPage 
        }
    
        if (rowsPerPages){
            setRowPerPages(rowsPerPages)
            customFilterByColumns.range.to = rowsPerPages 
        }
    
        return customFilterByColumns
    }
    
    const handleReload = () => {
        setReload(!reload)
    }
    
    useEffect(() => {
        if (!initRender){
            (async () => {
                setIsRefetching(true)
                await handleFecth(filterByColumns)
                setIsRefetching(false)
                setInitRender(true)
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        if (initRender){
            (async () => {
                setIsRefetching(true)
                await handleFecth(filterByColumns)
                setIsRefetching(false)
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByColumns]) 
        
    useEffect(() => {
        if (initRender){
            (async () => {
                setCurrentPage(1)
                const customFilters = filterByColumnsFactory({ country, type: integration, currentPage: 1, rowsPerPages })
                setFilterByColumns(customFilters)
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    return (
        <>
        <Button style={{ marginBottom:'10px' }} onClick={() => setShowIntegration({ name: '', show: false })} icon={<ArrowLeftOutlined />}>Ver integraciones</Button>
        <Card>
            <Table
                title={"Lista de clientes"}
                tableType="integrationsCustomers"
                data={dataColumns ?? []}
                total={total}
                isLoading={refetching}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(row) => setRowPerPages(row)}
                schemaDownload={usersByIntegrationDownloads}
                columns={usersByIntegration(setCustomerIntegrationData, setOpenModal) ?? []}
                config={{ disableFilters: true }}
                reload={handleReload}
                stateValues={{
                    country,
                    currentPage,
                    rowsPerPages,
                    searchText,
                    filterByColumns,
                }}
                filters={{
                    filterBySearchText(text) {
                        setSearchText(text)
                    },
                    filterByColumns(filter) {
                        setFilterByColumns(filter)
                    },
                    filterDownloads: async (filter) => {
                        filter.isDownload = true
                        return await handleFecth(filter, false)
                    },
                    handleStateFilterByColumns: (props) => {
                        return handleStateFilterByColumns(props)
                    },
                    filterByColumnsFactory: (props)=> {
                        return filterByColumnsFactory(props)
                    }
                }}
            />
        </Card>
        </>

    )
}

export default IntegrationCustomers