import { useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { movementsDownloads } from '../../components/tables/schemaDownload'
import { movementsColumns } from '../../components/tables/schemaColumn';
import { Card } from "antd";

const filterByColumnsFactory = ({
	country,
  isAdmin = true,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}) => ({
	country,
  isAdmin,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'user', find: false },
    { id: 'type', find: false },
    { id: 'description', find: false },
    { id: 'customer', find: false },
    { id: 'created_at', find: false },
    { id: 'metadata', find: false }
  ]
})

const Movements = () => {
  const { getTokenSilently } = useAuth0();
  const [country] = useState('MX')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [dataColumns, setDataColumns] = useState(null) 
  const [filterByColumns, setFilterByColumns] = useState(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
  const [reload, setReload] = useState(false)
  const [initRender, setInitRender] = useState(false)

  const handleFecth = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const { data } = await axios.post(
        `/movements/getAll`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )

    setTotal(data?.total)

    if (saveState){
      setDataColumns(data?.data)
    } else {
      return data?.data ?? []
    }
  }
  
  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }) => {	
    const customFilterByColumns = { 
        ...filterByColumns,
    }
  
    if (user) customFilterByColumns.user = user	
    if (country) customFilterByColumns.country = country
    if (searchText !== undefined) customFilterByColumns.searched = searchText
    
    if (currentPage){
      setCurrentPage(currentPage)
      customFilterByColumns.range.from = currentPage 
    }
  
    if (rowsPerPages){
      setRowPerPages(rowsPerPages)
      customFilterByColumns.range.to = rowsPerPages 
    }
  
    return customFilterByColumns
  }
  
  const handleReload = () => {
    setReload(!reload)
  }
  
  useEffect(() => {
    if (!initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
        setInitRender(true)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    if (initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns]) 
    
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
      <Card>
        <Table
          // title={"Movimientos"}
          tableType="movements"
          data={dataColumns ?? []}
          total={total}
          isLoading={refetching}
          onChangePage={(page) => setCurrentPage(page)}
          onChangeRowsPerPage={(row) => setRowPerPages(row)}
          schemaDownload={movementsDownloads}
          columns={movementsColumns() ?? []}
          config={{ disableFilters: true }}
          reload={handleReload}
          stateValues={{
            country,
            currentPage,
            rowsPerPages,
            searchText,
            filterByColumns,
          }}
          filters={{
            filterBySearchText(text) {
              setSearchText(text)
            },
            filterByColumns(filter) {
              setFilterByColumns(filter)
            },
            filterDownloads: async (filter) => {
              filter.isDownload = true
              return await handleFecth(filter, false)
            },
            handleStateFilterByColumns: (props) => {
              return handleStateFilterByColumns(props)
            },
            filterByColumnsFactory: (props)=> {
              return filterByColumnsFactory(props)
            }
          }}
        />
      </Card>
  )
}

export default Movements