import React, {useContext, useEffect, useState} from 'react';
import { axiosBase as axios } from '../../api/AxiosConfig';
import {useAuth0} from "../../hooks/react-auth0-spa";
import {Button, notification, Select, Table} from "antd";
import {format} from "date-fns";
import {IntegrationShipmentStatus} from "../../utils/States";
import {ArrowRightOutlined} from "@ant-design/icons";
import RateModal from '../manualDispatch/RateModal';
import { DollarOutlined } from '@ant-design/icons';
import {QuoteDataContext} from "../../context/QuoteDataContext";

function CustomerOrders({ setOpenModal }) {
    const { getTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false)
    const [filterOptions, setFilterOptions] = useState([])
    const [ordersData, setOrdersData] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPages, setRowPerPages] = useState(20)
    const [currentPageOrders, setCurrentPageOrders] = useState(1)
    const [rowsPerPagesOrders, setRowPerPagesOrders] = useState(20)
    const { setCustomerIntegrationData, customerIntegrationData } = useContext(QuoteDataContext);

    const getCustomerOrders = async(integrationInfo) => {
        try {
            setIsLoading(true)
            const token = await getTokenSilently();
            const response = await axios.get(
                `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/integrations/orders?id=${integrationInfo._id}&limit=50&page=1&offset=0`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            console.log('Response:', response)
            // console.log('Integracion seleccionada: ', integrationInfo)
            setCustomerIntegrationData({ ...customerIntegrationData, integrationInfo})
            if(response.status === 200) {
                if(response.data.length === 0){
                    notification.info({ message: 'No hay ordenes en esta tienda.' })
                } else {
                    const { data: orderResponse } = await axios.get(
                        `/orderIntegrations`,
                        { headers: { Authorization: `Bearer ${token}` }}
                    )
                    // console.log('Order response -------------->', orderResponse)
                    if(orderResponse.code !== 200){
                        notification.info({ message: 'ha ocurrido un problema.' })
                        setOpenModal(false)
                        setCustomerIntegrationData({})
                    } else {
                        const orderListIntegration = response.data
                        const orderListApi = orderResponse.res.map(item => item.order)
                        // console.log('orderListIntegration ------------>', orderListIntegration)

                        if(integrationInfo.type === 'MERCADOLIBRE'){
                            console.log('Entra aqui en mercadolibre')
                            const filteredOrderList = orderListIntegration.filter(item => {
                                if (!orderListApi.includes(item.order_number)){
                                    return item
                                }
                                return null
                            })
                            setOrdersData(filteredOrderList)
                            setIsLoading(false)
                            return
                        }
                        setOrdersData(orderListIntegration)
                    }
                }
            }
            setIsLoading(false)
        } catch (error) {
            notification.error({ message: "No se han podido obtener las ordenes, por favor, verificar estado de la integración con el cliente."})
            setIsLoading(false)
        }

    }

    const updateOrdersData = ()=> {
        setOrdersData([])
    }

    const optionsFilters = () => {
        if(!customerIntegrationData.integrationInfo) return
        switch (customerIntegrationData?.integrationInfo?.type) {
            case 'WOOCOMMERCE':
                setFilterOptions([
                    {
                        text: "Completado",
                        value: "completed"
                    },
                    {
                        text: "Cancelado",
                        value: "cancelled"
                    },
                    {
                        text: "Fallido",
                        value: "failed"
                    },
                    {
                        text: "Pendiente",
                        value: "pending"
                    },
                    {
                        text: "En espera",
                        value: "on-hold"
                    },
                    {
                        text: 'Procesando',
                        value: "processing"
                    },
                    {
                        text: "Reembolsado",
                        value: "refunded"
                    }
                ])
                break;
            case 'MERCADOLIBRE':
                setFilterOptions([
                    {
                        text: "Entregado",
                        value: "shipped"
                    },
                    {
                        text: 'Procesando',
                        value: "ready_to_ship"
                    },
                    {
                        text: 'Enviado',
                        value: "delivered"
                    },
                    {
                        text: 'Cancelado',
                        value: "cancelled"
                    },
                ])
                break;
            case 'SHOPIFY':
                setFilterOptions([
                    {
                        text: "No cumplido",
                        value: "UNFULFILLED"
                    },
                    {
                        text: "Completado",
                        value: "FULFILLED"
                    },
                    {
                        text: "En progreso",
                        value: "IN_PROGRESS"
                    },
                    {
                        text: "En espera",
                        value: "ON_HOLD"
                    },
                    {
                        text: "Abierto",
                        value: "OPEN"
                    }
                ])
                break;
            default:
                break;
        }
    }

    const handleTableChange = ({ page, pageSize }) => {
        setCurrentPage(page)
        setRowPerPages(pageSize)
    }

    const handleTableOrdersChange = ({ page, pageSize }) => {
        setCurrentPageOrders(page)
        setRowPerPagesOrders(pageSize)
    }

    const table_orders_columns = [
        {
            title: 'Fecha de creación',
            dataIndex: '',
            render: (data) => (<p>{ data?.created_at ? format(new Date(data.created_at),"dd/MM/yyyy HH:MM:SS") : 'N/A' }</p>)
        },
        {
            title: '# de Pedido',
            dataIndex: 'order_number',
        },
        {
            title: 'Nombre',
            dataIndex: '',
            render: (data) => (<p>{ data?.customer_details?.name?.first_name ?? "N/A" }</p>)
        },
        {
            title: 'Calle',
            dataIndex: '',
            render: (data) => (<p>{ data?.shipping_address?.address ?? "N/A" }</p>)
        },
        {
            title: 'Ciudad',
            dataIndex: '',
            render: (data) => (<p>{ data?.shipping_address?.city ?? "N/A" }</p>)
        },
        {
            title: 'Estado/País',
            dataIndex: '',
            render: (data) => (<p>{ `${data?.shipping_address?.province?.name ?? ''}, ${data?.shipping_address?.country?.name ?? ''}` }</p>)
        },
        {
            title: 'Código Postal',
            dataIndex: '',
            render: (data) => (<p>{ data?.shipping_address?.zip_code ?? "N/A" }</p>)
        },
        {
            title: 'Estatus',
            dataIndex: '',
            render: (data) => (
                <div style={{width:'100%'}}>
                    { changeDefaultValue(data) }
                </div>
            ),
            //TODO: Cuando se hace el filtrado por status, se hace bien, pero en los <Select> de Woocommerce, el status NO es el correcto
            // Se le queda un status fijo, entonces, toca arreglarlo. Solamente es visual, porque en cuanto a propiedad y filtro funciona correctamente.
            // Esto es para ocultar el filtro en las ordenes de Woocommerce, mientras se resuelve el bug.
            // En las demas integraciones, el filtro funcionara normalmente.
            ...(customerIntegrationData?.integrationInfo?.type !== 'WOOCOMMERCE' && {
                filters: filterOptions,
                onFilter: (value, record) => (record.fulfillment_info.status.indexOf(value) === 0)
            }),
        },
        {
            title: '',
            dataIndex: '',
            render: (data) => (
                <div>
                    <RateModal 
                        text="Cotizar"
                        Icon={<DollarOutlined />}
                        isFlexible={true}
                        customerData={customerIntegrationData}
                        integrationOrder={data}
                        updateOrdersData={updateOrdersData}
                    />
                </div>
            )
        },
        // {
        //     title: '',
        //     dataIndex: '',
        //     render: (data) => (
        //         <div>
        //             <RateModal
        //                 text="Cotizacion flexible"
        //                 Icon={<SwapOutlined />}
        //                 isFlexible={true}
        //                 data={customerIntegrationData}
        //                 integrationOrder={data}
        //                 updateOrdersData={updateOrdersData}
        //                 // integrationOrder={{
        //                 //     ...data,
        //                 //     product_items: [
        //                 //         {
        //                 //             "id": "sku123",
        //                 //             "name": "servo motor",
        //                 //             "total_price": 220,
        //                 //             "quantity": 1,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //         {
        //                 //             "id": "987",
        //                 //             "name": "Arduinooooooo",
        //                 //             "total_price": 220,
        //                 //             "quantity": 3,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //     ],
        //                 // }}
        //             />
        //         </div>
        //     )
        // },
        // {
        //     title: '',
        //     dataIndex: '',
        //     render: (data) => (
        //         <div>
        //             <RateModal
        //                 text="Cotizacion estricta"
        //                 Icon={<LockOutlined />}
        //                 isFlexible={false}
        //                 data={customerIntegrationData}
        //                 integrationOrder={data}
        //                 updateOrdersData={updateOrdersData}
        //                 // integrationOrder={{
        //                 //     ...data,
        //                 //     product_items: [
        //                 //         {
        //                 //             "id": "sku123",
        //                 //             "name": "servo motor",
        //                 //             "total_price": 220,
        //                 //             "quantity": 1,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //         {
        //                 //             "id": "987",
        //                 //             "name": "Arduinooooooo",
        //                 //             "total_price": 220,
        //                 //             "quantity": 3,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //     ],
        //                 // }}
        //             />
        //         </div>
        //     )
        // },
    ]

    const changeDefaultValue = (data) => {

        const optionsWoocommerce = [
            { label: "Completado", value: "completed" },
            { label: "Cancelado", value: "cancelled" },
            { label: "Fallido", value: "failed" },
            { label: "Pendiente", value: "pending" },
            { label: "En espera", value: "on-hold" },
            { label: 'Procesando', value: "processing" },
            { label: 'Reembolsado', value: "refunded" },
        ]

        if(!customerIntegrationData.integrationInfo.type){
            return
        }
        switch (customerIntegrationData.integrationInfo.type){
            case 'WOOCOMMERCE':
                const onChange = async (e) => {
                    setIsLoading(true)
                    const token = await getTokenSilently();
                    const request_to_update = {
                        fulfillmentData: {
                            id: data.id,
                            status: e
                        },
                        store: {
                        ...customerIntegrationData?.integrationInfo
                        },
                        type: 'WOOCOMMERCE',
                    }

                    try {
                        const response = await axios.post(
                            `/order/updateOrderStatus`,
                            {
                                request_to_update,
                                customerToken: customerIntegrationData.ApiClientsV2[0]?.prod_token?.token,
                            },
                            { headers: { Authorization: `Bearer ${token}` }}
                        )
                        if(response.status !== 200){
                            setIsLoading(false)
                            return notification.error({ message: "No se ha podido actualizar el status, intentalo nuevamente." })
                        }
                        setIsLoading(false)
                        return notification.success({ message: "Status actualizado correctamente." })
                    } catch (error) {
                        return notification.error({ message: "No se ha podido actualizar el status, intentalo nuevamente." })
                    }
                }
                return <Select style={{ width: '100%' }} defaultValue={{ label: IntegrationShipmentStatus(data.fulfillment_info?.status, 'NORMALIZE'), value: data.fulfillment_info?.status }} options={optionsWoocommerce} onChange={onChange} />
            default:
                return IntegrationShipmentStatus(data.fulfillment_info?.status, 'BADGE');
        }
    }

    const table_columns = [
        {
            title: 'Fecha de creación',
            dataIndex: "",
            render: (rowData) => (<p>{ rowData?.created_at ? format(new Date(rowData.created_at),"dd-MM-yyyy") : 'N/A' }</p>)
        },
        {
            title: "Nombre",
            dataIndex: "name",
        },
        {
            title: 'Fecha de actualización',
            dataIndex: "",
            render: (rowData) => (<p>{ rowData?.updated_at ? format(new Date(rowData.updated_at),"dd-MM-yyyy") : 'N/A' }</p>)
        },
        {
            title: 'Acciones',
            dataIndex: "",
            render: (rowData) => (<Button icon={<ArrowRightOutlined />} onClick={()=>getCustomerOrders(rowData)}>Ver ordenes</Button>)
        }
    ]

    useEffect(() => {
        optionsFilters()
    },[customerIntegrationData?.integrationInfo?.type])//eslint-disable-line

    return (
        <div style={{ overflow: 'scroll'}}>
            {
                ordersData.length === 0 ? (
                    <Table 
                        size='small'
                        loading={isLoading} 
                        columns={table_columns} 
                        dataSource={customerIntegrationData?.integrations ?? []}
                        scroll={{ x: true }}
                        pagination={{
                            current: currentPage,
                            pageSize: rowsPerPages,
                            total: customerIntegrationData?.integrations?.length ?? 0,
                            pageSizeOptions: ['5', '10', '20', '50', '100', '500'],
                            onChange: (page, pageSize) => handleTableChange({ page, pageSize })
                        }} 
                    />
                ) : (
                    <Table 
                        size='small'
                        loading={isLoading} 
                        columns={table_orders_columns} 
                        dataSource={ordersData} 
                        scroll={{ x: true }}
                        pagination={{
                            current: currentPageOrders,
                            pageSize: rowsPerPagesOrders,
                            total: ordersData?.length,
                            pageSizeOptions: ['5', '10', '20', '50', '100', '500'],
                            onChange: (page, pageSize) => handleTableOrdersChange({ page, pageSize })
                        }} 
                    />
                )
            }
        </div>
    );
}

export default CustomerOrders;