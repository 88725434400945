// import moment from 'moment'
import * as XLSX from 'xlsx'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import {Button, Table, Input, Tooltip} from 'antd'
import {ProfileOutlined, ReloadOutlined} from '@ant-design/icons'
import { useToggle } from '../../hooks/useToggle'
// import { ModalDataPicker } from './ModalDataPicker'
// import ModalContainer from './ModalContainer'
// import TabsFilterContainer from './TabsFilterContainer'
// import { ColumnsType } from 'antd/es/table'
// import { AllColumnsType, GetAllFilteredShipmentsQuery } from '../interfaces/tableSchema'
// import { useLocation } from 'react-router-dom'

const CustomTable = ({
  columns,
  data,
  title,
  config,
  isShipments,
  filters,
  isLoading,
  stateValues,
  onChangePage,
  onChangeRowsPerPage,
  total,
  optionalContent,
  tableType,
  noFormattedDate,
  reload,
  ...rest
}) => {
  const { toggle } = useToggle()
  const { toggle: toggleFiltersModal } = useToggle()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  // const [fileType, setFileType] = useState('xlsx')
  // const [rangeDate, setRangeDate] = useState(null) // [string, string] | null
  // const setFilterRange = useState([null, null])[1] // [Date | null, Date | null]
  const [searchInputText, setSearchInputText] = useState('') // string 
  // const [actionSelected, setActionSelected] = useState(null) // string | null

// xlsx | csv
  const beforeDownload = (fileType) => {
    downloadExcel(fileType)
    // setFileType(fileType)
    // toggle()
  }


  // xlsx | csv
  const downloadExcel = async (type) => {
    setIsButtonLoading(true)
    try {      
        let formatedData = data 
    //     if (rangeDate !== null && (rangeDate?.[0] || rangeDate[1])){
  
    //       const downloadFilters = { 
    //         isDownload: true, 
    //         country: stateValues.country, 
    //         currentPage: 1, 
    //         rowsPerPages: 10000000000000000 
    //       }
  
    //       if (stateValues?.user){
    //         downloadFilters.user = stateValues.user ? stateValues.user : undefined
    //         downloadFilters.isAdmin = stateValues.user ? false : true
    //       }
  
    //       const customFilters = filters.filterByColumnsFactory(downloadFilters)
  
    //       customFilters.columns.forEach((column, index ) => {  
    //         if (column.id === 'createdAt' || column.id === 'created_at'){
    //           customFilters.columns[index] = {
    //             id: column.id,
    //             find: true,
    //             filters: {
    //                 sort: false,
    //                 filter: true,
    //                 filter_values: [
    //                   rangeDate?.[0],
    //                   rangeDate?.[1] 
    //                 ]
    //             }
    //           }
    //         }
    //       })
  
    //       const updateData = await filters.filterDownloads(customFilters)
    //       formatedData = rest.schemaDownload(updateData, noFormattedDate)
    //   } 

      const worksheet = XLSX.utils.json_to_sheet(formatedData)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, `${title}`)
      XLSX.writeFile(workbook, `${title}.${type}`, { bookType: type })
    } catch (err) {
      console.log({ err })
    } finally {
      setIsButtonLoading(false)
      toggle()
    }
  }

  const handleTableChange = (pagination) => {
    const { page, pageSize } = pagination

    let tempFilters = stateValues.filterByColumns
    if (stateValues.rowsPerPages !== pageSize){
      tempFilters = filters.handleStateFilterByColumns({ country: stateValues.country, currentPage: 1, rowsPerPages: pageSize })
    } else {
      tempFilters = filters.handleStateFilterByColumns({ country: stateValues.country, currentPage: page })
    }
    filters?.filterByColumns && filters?.filterByColumns(tempFilters)
  }

  // prospects
//   const handleFiltersTableTitle = (tableType) => {
//     switch (tableType) {
//       case 'prospects':
//         return "mis prospectos"
//     }
//   }

  useEffect(() => {
    const tempFilters = filters.handleStateFilterByColumns({ country: stateValues.country, currentPage: 1, searchText: stateValues?.searchText })
    filters?.filterByColumns && filters?.filterByColumns(tempFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateValues?.searchText])

  const { Search } = Input
  
  return (
    <>
      <Table
        size="small"
        loading={isLoading}
        scroll={{ x: true }}
        pagination={{
          current: stateValues.currentPage,
          pageSize: stateValues.rowsPerPages,
          total,
          pageSizeOptions: ['5', '10', '20', '50', '100', '500'],
          onChange: (page, pageSize) => handleTableChange({ page, pageSize })
        }}
        title={() => (
          <div>
            {title && <Title level={4}>{title}</Title>}
            <div>
              {!config?.disableExport && (
                <div className="containerOptionsTable" style={{ display: 'flex', width: '100%' }}>
                  <div className="containerbuttons" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="downloadCSV">
                      <Button
                        loading={isButtonLoading}
                        onClick={() => beforeDownload('csv')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                      >
                        {"Descargar CSV"}
                      </Button>
                    </div>
                    <div className="downloadXLSX">
                      <Button
                        loading={isButtonLoading}
                        onClick={() => beforeDownload('xlsx')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                      >
                        {"Descargar XLSX"}
                      </Button>
                    </div>
                  </div>
                  <div className="containerButtonsTwo" style={{ display: 'flex', flexDirection: 'row' }}>
                    {!config?.disableFilters && (
                      <div className="applyFilter">
                        <Button
                          style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                          onClick={() => {
                            toggleFiltersModal()
                          }}
                        >
                          {"Filtrar"}
                        </Button>
                      </div>
                    )}
                    {optionalContent?.options && (optionalContent?.options)}
                  </div>
                </div>
              )}
            </div>
            <div className='container-search-table'>
              <Search
                  className="searchInput"
                  placeholder={"Buscar"}
                  onChange={(e) => {
                    setSearchInputText(e.target.value)
                  }}
                  onSearch={() => {
                    filters?.filterBySearchText(searchInputText)
                  }}
                  style={{
                    width: 200,
                    marginTop: '15px',
                    marginLeft: 'none',
                    marginRight: '10px'
                  }}

              />
              {
                reload && (
                      <Tooltip title={"Recargar datos"}>
                        <Button icon={<ReloadOutlined />} onClick={reload} style={{ marginTop:'15px', marginLeft:'none', marginRight:'10px' }}/>
                      </Tooltip>
                  )
              }
              {optionalContent?.search && (optionalContent?.search)}
            </div>
          </div>
        )}
        columns={columns}
        dataSource={data}
        rowSelection={config?.selection ? config?.selection : undefined}
      />
      {/* <ModalDataPicker
        isLoading={isButtonLoading}
        onAction={() => {
          if (!rangeDate?.[0] || !rangeDate[1]) {
            notification.warning({
              message: "Debe completar los campos"
            })
            return
          }
          downloadExcel(fileType)
        }}
        isOpen={state}
        onCancel={toggle}
        setState={(e) => {
          setRangeDate([moment(e[0].$d).format('YYYY-MM-DD'), moment(e[1].$d).format('YYYY-MM-DD')])
        }}
      /> */}
      {/* <ModalContainer
        title={`${t('Dashboard.Table.filters.MainTitle')} ${handleFiltersTableTitle(tableType)}`}
        visible={isFiltersOpen}
        onCancel={toggleFiltersModal}
        footer={false}
      >
        <TabsFilterContainer
          data={data}
          actionSelected={actionSelected}
          toggleFiltersModal={toggleFiltersModal}
          setFilterRange={setFilterRange}
          stateValues={stateValues}
          setFilterByColumns={filters?.filterByColumns}
          tableType={tableType}
        />
      </ModalContainer> */}
    </>
  )
}

export default CustomTable