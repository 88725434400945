import React, {useContext, useState} from 'react';
import { Card } from "antd";
import { integrations } from "./integrations-array";
import { StoreLogo } from "../../utils/StoreLogos";
import IntegrationCustomers from "./Customers";
import ModalContainer from "../quoter/containers/ModalContainer";
import CustomerOrders from "./CustomerOrders";

function IntegrationsDispatch() {
    const [showIntegration, setShowIntegration] = useState({ name: "", show: false });
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
        <div 
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                gap: "16px"
            }}
        >
            {
                !showIntegration.show && integrations.map((integration, index) => {
                    if (integration.status) {
                        return (
                            <Card onClick={() => setShowIntegration({ name: integration.name, show: true })} key={index} style={{ display:'flex', justifyContent:'center', alignItems: 'center', cursor:'pointer' }}>
                                <img style={{ width: '100%' }} src={StoreLogo(integration.name)} alt={integration.name} />
                            </Card>
                        )
                    }
                    return null
                })
            }
        </div>
            {showIntegration.show && <IntegrationCustomers setOpenModal={setOpenModal} integration={showIntegration.name} setShowIntegration={setShowIntegration}  />}
            {openModal && (
                <ModalContainer
                    size="80vw"
                    height='100%'
                    visible={openModal}
                    title="Ordenes del cliente"
                    onCancel={()=> setOpenModal(false)}
                    onOk={()=> {}}
                    footer={false}
                    maskClosable={false}
                >
                    <CustomerOrders setOpenModal={setOpenModal}/>
                </ModalContainer>
            )}
        </>
    );
}

export default IntegrationsDispatch;
