import React, { Fragment, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth0 } from "../../hooks/react-auth0-spa"
import {
  Grid,
  Box,
  Card,
  CardContent,
  TextField,
  InputLabel,
  Button,
  FormControl,
} from "@material-ui/core"
import { GetAllProductsByAuthId } from "../../api/ProductAPI"
import {  AutoComplete, Radio, Result, List, Avatar, Table } from 'antd';
import Info from '@material-ui/icons/Info'

const initialState = {
  Codigo: "",
  Guia: {
    Proveedor: "",
    Numero: "",
  },
  InfoEnvioProductos: [],
}
const initialStateProduct = {
  SKUFulfillment: "",
  Cantidad: "",
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
  textFieldSmall: {
    marginLeft: theme.spacing(2),
  },
}))

const styles = {
    container: {
      minHeight: '300px',
      maxHeight: '100px',
      overflow: 'auto',
      padding: '0 20px',
      marginTop: '24px',
      marginRight: '20px',
      boxShadow: '1px 1px 2px 2px rgba(140, 140, 140, 0.35)',
      borderRadius: '12px'
    },
    integrationCard: {
      display: 'flex',
    },
    radio: { margin: '8px 0px'},
    itemRadio: { display: 'flex', flexDirection: 'column', margin: '0px 15px'},
    skuCliente: { margin: '0', fontWeight: 'bold'},
    description: { margin: '0'},
    quantity: { fontWeight: 'bold' },
    containerMainInfo: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%', 
      minHeight: '50px',
      color: '#096dd9',
      backgroundColor: '#e6f7ff',
      borderColor: '#91d5ff',
      borderRadius: '4px',
      padding: '8px',
      marginBottom: '30px',
    },
    containerInfo: {
      color: '#d93025',
      backgroundColor: '#fdecea',
      borderColor: '#f5c2c7',
      borderRadius: '4px',
      padding: '8px',
      margin: '10px 0',
    },
    info: {
      fontSize: '18px', 
      marginRight: '6px', 
      verticalAlign: 'middle'
    },
}

const FlexibleSelectedProductsByIntegration = ({ userId, handleShipment, handleStep, integrationOrder }) => {
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  
  const [isLoading, setIsLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  
  const [searchProduct, setSearchProduct] = useState('')
  const [shipment, setShipment] = useState(initialState)
  const [product, setProduct] = useState(initialStateProduct)
  const [products, setProducts] = useState([])

  const [integrationProducts, setIntegrationProducts] = useState([])
  const [selectedIntegrationProducts, setSelectedIntegrationProducts] = useState(null)
  const [quantityIntegrationProducts] = useState(integrationOrder?.orderData?.product_items?.length)

  const getUserProducts = async() => {
      try {
        setIsLoading(true)

        // integration products 
        const integrationProducts = [ ...integrationOrder?.orderData?.product_items ]
        setIntegrationProducts(integrationProducts)

        // api products 
        const token = await getTokenSilently()
        const response = await GetAllProductsByAuthId(token, userId, { active: true })
        if(response) setProducts(response.res)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log('Error at getUserProducts', error)
    }
  }

  const onChangeInputProduct = (e) => {
    const { name, value } = e.target
    let currentProduct

    let updatedProduct = {
      ...product,
      [name]: value,
    }

    if (name === "SKUFulfillment") {
      currentProduct = products.find((product) => product.SKUFulfillment === value)
      if (currentProduct) {
        updatedProduct = {
          ...updatedProduct,
          Nombre: currentProduct.Nombre,
        }
      }
    }

    setProduct(updatedProduct)
    setAlertMessage('')
  }

  const onSubmitProduct = async (e) => {
    e.preventDefault()

    const selectedProduct = products.find(item => item.SKUFulfillment === product.SKUFulfillment)    
    if (product.Cantidad > selectedIntegrationProducts.quantity){
      setAlertMessage('La cantidad seleccionada excede el número de unidades disponibles en la orden.')
      return 
    }

    if (selectedProduct.CantidadEnInventario < product.Cantidad){
      setAlertMessage('La cantidad disponible en inventario es insuficiente para cubrir la cantidad solicitada')
      return 
    }
    
    const productToSave = {
      Producto: product.SKUFulfillment,
      CantidadEnviada: product.Cantidad,
      Nombre: product.Nombre,
    }

    setShipment({
      ...shipment,
      InfoEnvioProductos: [...shipment.InfoEnvioProductos, productToSave],
    })

    setProduct(initialStateProduct)
    setIntegrationProducts([ ...integrationProducts.filter(item => item.id !== selectedIntegrationProducts.id)])
    setSelectedIntegrationProducts(null)
    setSearchProduct('')
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    handleShipment(shipment)
    handleStep()
  }

  useEffect(() => {
    getUserProducts()
    //eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Grid container>
      </Grid>
      <Box>
        <Card>
          <CardContent>
            <form autoComplete="off" onSubmit={onSubmitProduct}>
              <Grid item xs={12}
                container
                alignItems="center"
                justify="center"
              >
                <div style={styles.containerMainInfo}>
                  <Info style={styles.info}/>
                  Selecciona y vincula los productos de la orden con los productos de tu inventario interno para mantener el control de existencias.
                </div>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Productos de orden {integrationOrder?.orderData?.order_number}</InputLabel>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12} lg={6}>
                    <div style={styles.container} >
                      {shipment.InfoEnvioProductos.length === quantityIntegrationProducts ? (
                        <Result status="success" title={'¡Validacion exitosa!'}/>
                      ) : (
                        // <Radio.Group onChange={(e)=> setSelectedIntegrationProducts(e.target.value)}>
                        //   {integrationProducts.map(item => (
                        //     <div style={styles.integrationCard}>
                        //         <Radio value={item} style={styles.radio} >
                        //           <div style={styles.itemRadio}>
                        //             <p style={styles.skuCliente}>{`${item.id}`}</p>
                        //             <p style={styles.description}> <span style={styles.quantity}>{item.quantity}</span> {item.name}</p>
                        //           </div>
                        //         </Radio>
                        //     </div>
                        //   ))}
                        // </Radio.Group>
                        <Table
                          columns={[
                            {
                              title: "",
                              dataIndex: "",
                              align: 'center',
                              render: (item) => (
                                <Radio
                                  value={item}
                                  checked={selectedIntegrationProducts === item}
                                  onChange={(e) => setSelectedIntegrationProducts(e.target.value)}
                                />
                              ),
                            },
                            {
                              title: "SKU Cliente",
                              dataIndex: "",
                              render: (item) => (
                                  <p>{item?.id?.length > 30 ? 'N/A' : item?.id}</p>
                              ),
                              align: 'center',
                            },
                            {
                              title: "Descripción",
                              dataIndex: "",
                              render: (item) => (
                                  <p>{ item?.name || item?.title || 'N/A' }</p>
                              ),
                              align: 'center',
                            },
                            {
                              title: "Cantidad",
                              dataIndex: "quantity",
                              align: 'center',
                            },
                          ]}
                          dataSource={integrationProducts}
                          pagination={false}
                        />
                      )}
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid item xs={12} lg={10}>
                  <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      required={true}
                  >
                      <AutoComplete
                        name="SKUFulfillment"
                        disabled={isLoading
                          ? isLoading
                          : selectedIntegrationProducts === null
                            ? selectedIntegrationProducts === null
                            : products.length === 0
                        }
                        value={searchProduct}
                        allowClear={true}
                        placeholder="Producto *"
                        style={{ height: '56px'}}
                        options={isLoading
                            ? []
                            : products.length === 0
                                ? [{ label: "No hay productos", value: "No hay productos" }]
                                : products.map((item) => ({
                                label: `${item.SKUCliente} - ${item.Nombre}`,
                                value: item.SKUFulfillment
                                }))
                        }
                        filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSearch={(value)=> setSearchProduct(value)}
                        onClear={()=> {
                            setSearchProduct('')
                            setAlertMessage('')
                            setProduct({ SKUFulfillment: '', Cantidad: product.Cantidad })
                        }}
                        onSelect={(value)=> {
                            if (value !== 'No hay productos'){
                              const chosenProduct = products.find((product) => product?.SKUFulfillment === value)
                              setSearchProduct(`${chosenProduct?.SKUCliente} - ${chosenProduct?.Nombre}`)
                              onChangeInputProduct({ target: { name: 'SKUFulfillment', value }})
                            } else {
                              setSearchProduct(value)
                              setAlertMessage('')
                            }
                        }}
                      />
                  </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={10}>
                  <TextField
                      type="number"
                      name="Cantidad"
                      label="Cantidad"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      required={true}
                      value={product.Cantidad}
                      onChange={onChangeInputProduct}
                      inputProps={{ min: 1 }}
                      disabled={isLoading
                        ? isLoading
                        : selectedIntegrationProducts === null
                          ? selectedIntegrationProducts === null
                          : products.length === 0
                      }
                  />
                  </Grid>

                  {alertMessage !== '' && (
                    <Grid
                      item xs={12} lg={10}
                      container
                      alignItems="center"
                      justify="center"
                    >
                      <div style={styles.containerInfo}>
                        <Info  style={styles.info}/>
                        {alertMessage}
                      </div>
                    </Grid>
                  )}

                  <Grid
                    item xs={10} lg={4}
                    container
                    alignItems="center"
                    justify="center"
                  >
                    <Button
                      disabled={
                      (product?.SKUFulfillment === '') ||
                      (product?.Cantidad === '') ||
                      (!product?.Nombre) ||
                      (!products.find((currentProduct) =>
                        `${currentProduct.SKUCliente} - ${currentProduct.Nombre}` === searchProduct &&
                        currentProduct?.SKUFulfillment === product?.SKUFulfillment
                      ))
                      }
                      type="submit"
                      className={classes.textField}
                      variant="outlined"
                      color="primary"
                    >
                      Agregar producto
                    </Button>
                  </Grid>
                  
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={3} width={1}>
                  <List
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3 }}
                    dataSource={[ ...shipment.InfoEnvioProductos ]}
                    renderItem={(item) => {
                      const foundProduct = products.find(product => product._id === item.Producto)
                      const img = foundProduct?.Fotografia ?? ''

                      return (
                        <List.Item>
                          <Card title={item.Producto}>
                            <div className="productItem">
                              <div className="productItem-containerAvatar">
                                <Avatar src={img} className="productItem-avatar" />
                              </div>
                              <div className="productItem-containerDescription">
                                <p><strong>Producto:</strong> {item.Producto}</p>
                                <p><strong>Nombre:</strong> {item.Nombre}</p>
                                <p><strong>Cantidad:</strong> {item.CantidadEnviada}</p>
                              </div>
                            </div>
                          </Card>
                        </List.Item>
                      )
                    }}
                  />
                </Box>
              </Grid>
            </form>
            <form autoComplete="off" onSubmit={onSubmit}>
              <Grid container>
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    disabled={shipment.InfoEnvioProductos.length !== quantityIntegrationProducts}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.textField}
                  >
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  )
}

export default FlexibleSelectedProductsByIntegration