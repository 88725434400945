import React, { useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { outboundsDownloads } from '../../components/tables/schemaDownload'
import {outboundShipmentsAdmin} from '../../components/tables/schemaColumn';
import {Button, Card, Modal} from 'antd';
import {format} from "date-fns";
import {DownloadOutlined} from "@ant-design/icons";
import {GetAllUsers} from "../../api/userBalanceData";

const filterByColumnsFactory = ({
	country,
  isAdmin = true,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}) => ({
	country,
  isAdmin,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'EnvioId', find: false },
    { id: 'Estatus', find: false },
    { id: 'FechaEnvio', find: false },
  ]
})

const WarehouseOutputIndex = () => {
  const { getTokenSilently } = useAuth0()
  const [country] = useState('MX')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [dataColumns, setDataColumns] = useState(null) 
  const [filterByColumns, setFilterByColumns] = useState(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
  const [reload, setReload] = useState(false)
  const [initRender, setInitRender] = useState(false)
  const [openModal, setOpenModal] = useState({ open: false, data: undefined })

  const handleFecth = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const { data } = await axios.post(
        `/outboundShipments/get/v2`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )

    if (saveState){
      const filterByColumns = {
        country: 'MX',
        isAdmin: false,
        searched: '',
        range: { from: 1, to: 1000 },
        columns: []
      }
      const allUsers = await GetAllUsers(userToken, filterByColumns)
      const filteredUsers = allUsers?.data.filter(item => item.ApiClientsV2.length > 0)
      if(data?.data.length > 0){
        const newArray = []
        data?.data.forEach(item => {
          newArray.push({
            ...item,
            envioId: item?.EnvioId ?? "",
            email: item?.user.email ?? "",
            estatus: item?.Estatus ?? "",
            fechaEnvio: item?.FechaEnvio ?? "",
            user: item?.UsuarioId ?? "",
            ApiClientsV2: filteredUsers.find(fi => fi.user === item.UsuarioId)?.ApiClientsV2 ?? []
          })
        })
  
        if(newArray.length > 0){
          setTotal(data?.total)
          setDataColumns(newArray)
        }
      }
    } else {
      return data?.data ?? []
    }
  }

  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }) => {	
    const customFilterByColumns = { 
        ...filterByColumns,
    }
  
    if (user) customFilterByColumns.user = user	
    if (country) customFilterByColumns.country = country
    if (searchText !== undefined) customFilterByColumns.searched = searchText
    
    if (currentPage){
      setCurrentPage(currentPage)
      customFilterByColumns.range.from = currentPage 
    }
  
    if (rowsPerPages){
      setRowPerPages(rowsPerPages)
      customFilterByColumns.range.to = rowsPerPages 
    }
  
    return customFilterByColumns
  }

  const handleReload = () => {
    setReload(!reload)
  }

  useEffect(() => {
    if (!initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
        setInitRender(true)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByColumns]) 
  
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
      <Card>
        <Modal
            open={openModal.open}
            onCancel={() => setOpenModal({data: undefined, open: false})}
            footer={<></>}
        >

          <h3>Productos enviados</h3>
          {
            !openModal?.data
                ? (<p>No hay datos</p>)
                : openModal?.data && openModal.data.productsData.length > 0
                    ? (
                        openModal.data?.productsData?.map((item) => {
                          return (
                              <>
                                {
                                  openModal.data.InfoEnvioProductos.map((producto, index) => {
                                    return (
                                        <div style={{ margin:'20px 0' }}>
                                          <p style={{ margin: 0, padding: 0 }}><span
                                              style={{fontWeight: '600'}}>{index + 1} )</span> {item.SKUCliente} - {item.Nombre}
                                          </p>
                                          <p style={{ margin: 0, padding: 0, fontWeight:'600' }}>Cantidad a Enviar - {producto?.CantidadEnviada ?? 0}</p>
                                          <p style={{ margin: 0, padding: 0, fontWeight:'600' }}>Cantidad Despachada - {producto?.CantidadRecibida ?? 0}</p>
                                        </div>
                                    )
                                  })
                                }
                              </>
                          )
                        })
                    )
                    : <p>No hay productos</p>
          }

          <h3 style={{ margin:0, padding: 0 }}>Fecha de envio</h3>
          <p style={{ margin:0, padding: 0 }}>{ openModal?.data?.FechaEnvio
              ? format(
                  new Date(openModal?.data?.FechaEnvio),
                  "dd/MM/yyyy HH:MM:SS"
              ) : "NA" }
          </p>

          <Button icon={<DownloadOutlined />} style={{ margin:'20px 0' }} type="primary" disabled={!openModal?.data?.GuiaURL} onClick={() => window.location.href = openModal?.data?.GuiaURL}>{ openModal?.data?.GuiaURL ? "Descargar Guía" : "Guía no disponible" }</Button>

        </Modal>
        <Table
            tableType="outboundShipments"
            data={dataColumns ?? []}
            total={total}
            isLoading={refetching}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRowsPerPage={(row) => setRowPerPages(row)}
            schemaDownload={outboundsDownloads}
            columns={outboundShipmentsAdmin(setOpenModal) ?? []}
            config={{ disableFilters: true }}
            reload={handleReload}
            stateValues={{
              country,
              currentPage,
              rowsPerPages,
              searchText,
              filterByColumns,
            }}
            filters={{
              filterBySearchText(text) {
                setSearchText(text)
              },
              filterByColumns(filter) {
                setFilterByColumns(filter)
              },
              filterDownloads: async (filter) => {
                filter.isDownload = true
                return await handleFecth(filter, false)
              },
              handleStateFilterByColumns: (props) => {
                return handleStateFilterByColumns(props)
              },
              filterByColumnsFactory: (props)=> {
                return filterByColumnsFactory(props)
              }
            }}
        />
      </Card>
  )
}

export default WarehouseOutputIndex
