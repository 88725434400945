import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    CreateOutboundShipmentV2
} from "../../api/OutboundShipmentsAPI";
import {useAuth0} from "../../hooks/react-auth0-spa";
import {GetAllProducts} from "../../api/ProductAPI";
import {useAlert} from "react-alert";
import {Button, Card, Col, Form, Input, Row, Select, Table} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import InputCountriesContainer from '../../pages/quoter/containers/InputCountriesContainer'
// import axios from "axios";

// const initialState = {
//     postalCode:'',
//     city: '',
//     state: '',
//     district: ''
// }

const CreateOutboundShipments = (props) => {
    const History = useHistory();
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const [destinationData, setDestinationData] = useState(initialState)
    // const [optionLocationData, setOptionLocationData] = useState([])

    const [form] = Form.useForm();
    const [formCP] = Form.useForm();

    const [quoteInfo, setQuoteInfo] = useState(null)
    const [selectedProducts, setSelectedProducts] = useState([]);

    const {getTokenSilently} = useAuth0()
    const [token, setToken] = useState('')
    const alert = useAlert()

    const getUserProducts = async () => {
        setIsLoading(true)
        const token = await getTokenSilently();
        try {
            if (token) {
                const response = await GetAllProducts(token)
                    console.log('response ---->',response)
                if (response) {
                    const formattedProducts = response.map(product => ({
                        label: `${product.SKUCliente} - ${product.Nombre}`,
                        value: `${product.SKUCliente} - ${product.Nombre} - ${product._id}`,
                    }))
                    setProducts(formattedProducts)
                    // console.log('Response formattedProducts ------>', formattedProducts)
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log('Error at getUserProducts', error)
        }
    }

    const onSubmitProducts = (e) => {
        if (!e.Producto || !e.Cantidad){
            return alert.info("Debes añadir un producto y su cantidad.")
        }

        setSelectedProducts([
            ...selectedProducts,
            {
                SKU: e.Producto.split('-')[0].trim(),
                Producto: e.Producto.split('-')[1].trim(),
                ProductId: e.Producto.split('-')[2].trim(),
                Cantidad: e.Cantidad
            }
        ])
        form.resetFields()
    }

    const createOutboundShipment = async(e) => {
        if(selectedProducts.length === 0){
            return alert.error("Selecciona un producto antes de generar una orden.")
        }
        
        delete e.Producto
        delete e.Cantidad
        
        const quote = formCP.getFieldValue('quote_info')
        if (!quote) {
            return alert.error("Debes seleccionar un codigo postal valido.")
        }

        if (quoteInfo?.entireString !== quote){
            return alert.error("Debes seleccionar un codigo postal valido.")
        }

        const destination = {
            name: e?.name,
            email: e?.email,
            phone: e?.phone,
            company: e?.company,
            street: e?.street,
            number: e?.number,
            int_number: e?.int_number,
            district: quoteInfo?.entireString?.split('~')[1].trim(),
            city: quoteInfo?.entireObject?.locality,
            postal_code: quoteInfo?.postalCode,
            country: quoteInfo?.entireObject?.country.code,
            state: quoteInfo?.entireObject.state.name,
            reference: e?.reference
        }

        const token = await getTokenSilently();
        const request = {
            destination,
            products: selectedProducts,
        }

        const res = await CreateOutboundShipmentV2(token, request)
        if(res.status !== 200 || !res.status){
            return alert.error("Error al generar la orden")
        }
        alert.success("Tu envio al almacén fue registrado con éxito");
        await getUserProducts()
        History.push("/outboundshipments");
    }

    // const getLocationData = async(pc) => {
    //     try {
    //         const response = await axios.get(`https://geocodes.envia.com/zipcode/mx/${pc}`)
    //         if(response.data.length === 0){
    //             return
    //         }
    //         const { zip_code, state, locality, suburbs, regions } = response.data[0]
    //         const options = []
    //
    //         if(suburbs.length === 0){
    //             options.push({
    //                 label: `${zip_code} ~ ${regions.region_2} (${regions.region_1})`,
    //                 value: {
    //                     zip_code: zip_code,
    //                     district: regions.region_2,
    //                     city:
    //                 },
    //             })
    //         } else {
    //             suburbs.forEach((suburb) => {
    //                 options.push({
    //                     label: `${zip_code} ~ ${suburb} ~ ${regions.region_2} (${regions.region_1})`,
    //                     value: `${zip_code} ~ ${suburb} ~ ${regions.region_2} (${regions.region_1})`,
    //                 })
    //             })
    //         }
    //
    //         console.log('---------------------- OPCIONES ------------------>',options)
    //         setOptionLocationData(options)
    //
    //         console.log('response ==========>',response.data[0])
    //     } catch (error) {
    //         console.error(error)
    //         return error.response.data
    //     }
    // }

    // useEffect(() => {
    //
    //     if(destinationData.postalCode.length === 5){
    //         getLocationData(destinationData.postalCode)
    //     }
    //
    // }, [destinationData.postalCode]);

    useEffect(() => {
        getUserProducts()
        form.setFieldsValue({
            country: 'MX'
        })
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const establishToken = async () => { 
            const token = await getTokenSilently()
            setToken(token)
        }

        establishToken()
        //eslint-disable-next-line
    }, [])

    return (
        <>
        <Button type="primary" icon={<ArrowLeftOutlined /> } style={{ margin:'10px 0' }} onClick={() => History.push('/outboundshipments')}>Ver ordenes de envío</Button>
        <Row gutter={[10, 10]}>

            <Col span={12}>
                <Card>
                    <h3>Productos a enviar</h3>

                    <Form onFinish={onSubmitProducts} form={form}>
                    <Row>
                            <Col span={12}>
                                <Form.Item name="Producto">
                                    <Select
                                        placeholder="Selecciona el producto"
                                        showSearch
                                        optionFilterProp="label"
                                        options={products ?? []}
                                        style={{width: '100%'}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="Cantidad">
                                    <Input type="number" placeholder="Cantidad"/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Button style={{width: '100%'}} htmlType="submit">Agregar</Button>
                            </Col>
                            <Col span={24}>
                                <Table
                                    dataSource={selectedProducts ?? []}
                                    columns={[
                                        {
                                            title: "Producto",
                                            dataIndex: 'Producto',
                                            key: "Producto"
                                        },
                                        {
                                            title: "SKU",
                                            dataIndex: 'SKU',
                                            key: "SKU"
                                        },
                                        {
                                            title: "Cantidad",
                                            dataIndex: 'Cantidad',
                                            key: "Cantidad"
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>

            <Col span={12}>
                <Card>
                    <Form form={formCP} onFinish={createOutboundShipment}>
                        <h3 style={{textAlign: 'center'}}>Dirección del destino</h3>
                        <p>Contacto</p>
                        <Row gutter={[10, 10]}>
                            <Col span={12}>
                                <Form.Item 
                                    name='name'
                                    rules={[
                                        { required: true, message: "Este campo requerido" }, 
                                        { min: 4, message: "Minimo 4 caracteres" }, 
                                        { max: 30, message: "Maximo 30 caracteres" }
                                    ]} 
                                >
                                    <Input minLength={4} maxLength={30} showCount placeholder='Nombre'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    name='email'
                                    rules={[
                                        { required: true, message: "Este campo requerido" }, 
                                        { min: 4, message: "Minimo 4 caracteres" }, 
                                        { max: 32, message: "Maximo 32 caracteres" }
                                    ]} 
                                >
                                    <Input minLength={4} maxLength={32} showCount placeholder='Correo'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    name='phone' 
                                    rules={[
                                        { required: true, message: "Este campo requerido" }, 
                                        { min: 10, message: "Minimo 10 caracteres" }, 
                                        { max: 10, message: "Maximo 10 caracteres" }
                                    ]}
                                >
                                    <Input minLength={10} maxLength={10} showCount placeholder='Teléfono'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    name='company' 
                                    rules={[
                                        { min: 3, message: "Minimo 4 caracteres" }, 
                                        { max: 30, message: "Maximo 30 caracteres" }
                                    ]}
                                >
                                    <Input minLength={4} maxLength={30} showCount placeholder='Empresa ( opcional )'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <p>Dirección</p>
                        <Row gutter={[10, 10]}>
                            {/*<Col span={24}>*/}
                            {/*    <Form.Item name='postal_code' rules={[{required: true, message: "Este campo requerido"}]}>*/}
                            {/*        /!*<Select showCount showSearch onChange={(e) => setDestinationData(...destinationData, )} options={optionLocationData ?? []} onSearch={(e) => setDestinationData({ ...destinationData, postalCode: e })} placeholder='Código Postal' minLength={5} maxLength={5}/>*!/*/}
                            {/*        <Input showCount placeholder='Código Postal' minLength={5} maxLength={5}/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col span={12}>
                                <Form.Item 
                                    name='street' 
                                    rules={[
                                        { required: true, message: "Este campo requerido" }, 
                                        { min: 4, message: "Minimo 4 caracteres" }, 
                                        { max: 32, message: "Maximo 32 caracteres" }
                                    ]}
                                >
                                    <Input minLength={4} maxLength={32} showCount placeholder='Calle'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    name='number' 
                                    rules={[
                                        { required: true, message: "Este campo requerido" }, 
                                        {  min: 1, message: "Minimo 1 caracter" }, 
                                        { max: 14, message: "Maximo 14 caracteres" }
                                    ]}
                                >
                                    <Input minLength={1} maxLength={14} showCount placeholder='Número'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    name='int_number' 
                                    rules={[
                                        { min: 2, message: "Minimo 2 caracteres" }, 
                                        { max: 14, message: "Maximo 14 caracteres" }
                                    ]}
                                >
                                    <Input minLength={2} maxLength={14} showCount placeholder='Número interior ( opcional )'/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <InputCountriesContainer 
                                    token={token}
                                    textPlaceHolder={'Buscar una dirección'}
                                    type="localCountriesPostalCodesInputs"
                                    showUserAddresses
                                    userFormHook={formCP}
                                    required
                                    valueContainerName="quote_info"
                                    onPostalCodeChange={(e) => {
                                        if (e?.entireObject){
                                            setQuoteInfo(e)
                                        } else {
                                            setQuoteInfo(null)
                                        }
                                    }}
                                />
                            </Col>
                            <Col span={24}>
                                <Form.Item 
                                    name='reference' 
                                    rules={[
                                        { required: true, message: "Este campo requerido" }, 
                                        { min: 4, message: "Minimo 4 caracteres" }, 
                                        { max: 30, message: "Maximo 30 caracteres" }
                                    ]}
                                >
                                    <Input minLength={4} maxLength={30} showCount placeholder='Referencia'/>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Button disabled={isLoading} type='primary' style={{width: '100%', margin: '10px 0'}} htmlType='submit' loading={isLoading}>CREAR ORDEN DE ENVÍO</Button>
                    </Form>
                </Card>
            </Col>

        </Row>
        </>
    );
};

export default CreateOutboundShipments;
